import * as echarts from 'echarts';
import ReactECharts from 'echarts-for-react';
import { useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { HRZonesSummary } from '../../models';
import { UserWeeklySummary } from '../prisma';
import { BASE_URL, serverUrl } from '../const';

type EChartsOption = echarts.EChartsOption;


interface ZonesBarChartProps {
  weekNb: number;
  year: number;
  athleteId: number;
}
export function ZonesBarChart({ year, weekNb, athleteId }: ZonesBarChartProps): JSX.Element {
  const url = `${BASE_URL}/hr-summary/${athleteId}/${year}/${weekNb}`;

  const { data, error } = useSWR<UserWeeklySummary>(
    url,
    (url) => fetch(url, { mode: 'cors' }).then((res) => res.json()),
    {}
  );

  useEffect(() => {
    if (data) console.log(data);
  }, [data]);

  useEffect(() => {
    if (error) console.error(`ZonesBarChart, error while retrieving data: ${error}`);
  }, [error]);

  const options: EChartsOption | undefined = useMemo(() => {
    if (!data) return;

    const yearWeeks = Object.keys(data);
    console.log({ weeks: yearWeeks, week: weekNb, weeksWeek: yearWeeks[weekNb] });
    if (weekNb === undefined) return;

    const o: EChartsOption = {
      title: {
        text: `Week ${weekNb} - Time spent in HR Zones`,
        subtext:
          'Minutes spent during the last week in the different heart rate zones (Z1 = lowest heart rate, Z5 = highest heart rate)',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      xAxis: {
        type: 'category',
        data: ['Zone 1', 'Zone 2', 'Zone 3', 'Zone 4', 'Zone 5'],
        // name: "Time spent in zones"
      },
      yAxis: {
        type: 'value',
        // name: "Duration (minutes)"
      },
      series: [
        {
          name: 'Running',
          stack: 'HR',
          data: [data.run_zone_1, data.run_zone_2, data.run_zone_3, data.run_zone_4, data.run_zone_5].map((seconds) =>
            parseInt((seconds / 60).toFixed(0), 10)
          ),
          type: 'bar',
          showBackground: true,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#83bff6' },
              { offset: 0.5, color: '#188df0' },
              { offset: 1, color: '#188df0' },
            ]),
          },
        },
        {
          name: 'Cycling',
          stack: 'HR',
          data: [data.ride_zone_1, data.ride_zone_2, data.ride_zone_3, data.ride_zone_4, data.ride_zone_5].map(
            (seconds) => parseInt((seconds / 60).toFixed(0), 10)
          ),
          type: 'bar',
          showBackground: true,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#790909' },
              { offset: 0.5, color: '#950f0f' },
              { offset: 1, color: '#c20e0e' },
            ]),
          },
        },
      ],
    };

    return o;
  }, [data, weekNb]);

  return options ? <ReactECharts option={options} lazyUpdate /> : <></>;
}
