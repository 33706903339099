import { StravaProfilePassport } from '../models';
import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface ProfileStore {
  profile: StravaProfilePassport | undefined;
  token?: string;
  setProfile: (profile: StravaProfilePassport) => void;
  setToken: (token: string) => void;
}
export const useProfileStore = create<ProfileStore>()(
  devtools(
    persist(
      (set) => ({
        profile: undefined,
        token: undefined,
        setProfile: (profile: StravaProfilePassport) => set((state) => ({ ...state, profile })),
        setToken: (token: string) => set((state) => ({ ...state, token })),
      }),
      {
        name: 'profile-store',
      }
    )
  )
);

interface UserStore {
  user: {
    maxHR?: number;
    profilePicture?: string;
    city?: string;
    country?: string;

    measurementPreference?: string;

    lastUpdated?: Date;

    email?: string;
  };
  setUser: (user: UserStore['user']) => void;
  setMaxHR: (maxHR: number) => void;
  setEmail: (email: string) => void;
}

export const useUserStore = create<UserStore>()(
  devtools(
    persist(
      (set) => ({
        user: {},
        setUser: (user: UserStore['user']) => set({ user }),
        setMaxHR: (maxHR: number) => set((state) => ({ user: { ...state.user, maxHR } })),
        setEmail: (email: string) => set((state) => ({ user: { ...state.user, email } })),
      }),
      {
        name: 'user-store',
      }
    )
  )
);
